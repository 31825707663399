import React from 'react'
import Layout from '../components/layout'

import { Container, Row, Col } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'

import * as styles from "../styles/backline.module.css";
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RouteComponentProps } from "@reach/router"

interface BacklineQueryProps extends RouteComponentProps {
    data: {
        allStrapiBacklineCategory: {
            nodes: {
                slug: string
                name: string
                image: {
                    localFile: ImageDataLike
                }
            }[]
        },
        strapiBacklinePage: {
            background: {
                localFile: ImageDataLike
            }
            description: string
        }
    }
}

export default function Backline({ data, location }: BacklineQueryProps) {
    const { allStrapiBacklineCategory, strapiBacklinePage } = data;
    const lang = "hu";
    return (
        <Layout lang={lang} pageMeta={{ title: "Backline" }} background={strapiBacklinePage?.background?.localFile} location={location} defaultLang="hu">
            <Container className={styles.backline_category_content_wrapper}>
                <Row xs={2} md={3} xl={4} className={styles.backline_category_tiles}>
                    {allStrapiBacklineCategory.nodes.map(({ slug, name, image }) => {
                        const backline_img = getImage(image.localFile)!
                        return (
                            <Col className={styles.backline_category_tile_column} key={slug}>
                                <Link to={`/backline/${slug}`} className={styles.backline_category_tile_wrapper}>
                                <GatsbyImage image={backline_img} alt={name} className={styles.backline_category_tile_img} />
                                <span className={styles.backline_category_tile_overlay}>
                                    <p className={styles.backline_category_tile_text}>
                                    {name}
                                    </p>
                                </span>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </Layout>
    )
}


export const query = graphql`
query BacklineQuery {
    allStrapiBacklineCategory {
        nodes {
            slug
            name
            image {
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            layout: CONSTRAINED
                            width: 500
                            aspectRatio: 1
                            transformOptions: {cropFocus: CENTER, fit: CONTAIN}
                        )
                    }
                }
            }
        }
    }
}
`